<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        @click="openModal()"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :columns="columns"
      :resource="resource"
    />
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <gift-card-editor :gift-card="entity" @submit="handleSubmit" />
    </b-modal>
  </div>
</template>

<script>
import {
  VxCellButtons,
  VxCellDate,
  VxCellMoney,
  VxTable
} from '@/components/table'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { giftCards, passDataToResource, useResource } from '@/services/resources'
import { ref } from '@vue/composition-api'
import { useModalEditor } from '@/services/modal'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { buildRoute, path } from '@/router'
import { sortOrders } from '@/services/table'
import GiftCardEditor from '@/views/gift-cards/GiftCardEditor'
import { BModal } from 'bootstrap-vue'

export default {
  name: 'GiftCards',
  components: {
    VxTable,
    VxPageTitleActions,
    VxButton,
    VxCellMoney,
    VxCellDate,
    VxCellButtons,
    GiftCardEditor,
    BModal
  },
  setup () {
    const tableRef = ref(null)

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()

    const { can: canCreate } = useResource(giftCards.create)
    const { can: canUpdate } = useResource(giftCards.update)
    const { can: canViewKeys } = useResource(giftCards.getKeys)

    const modalTitle = 'Create Gift Card'

    const handleSubmit = () => {
      closeModal()
      tableRef.value.refresh()
    }

    const resource = passDataToResource(giftCards.getAll, {
      requestParams: {
        params: { include: 'totals' }
      }
    })

    const statusButton = ({ id, is_active }) => {
      const payload = {
        requestParams: {
          urlParams: { id },
          data: { is_active: !is_active }
        }
      }

      const updatedGiftCardStatus = passDataToResource(giftCards.update, payload)

      const turnOff = { ...buttons.turnOff, label: 'Enabled', can: canUpdate }
      const turnOn = { ...buttons.turnOn, label: 'Disabled', can: canUpdate }

      return [{
        ...is_active ? turnOff : turnOn,
        resource: updatedGiftCardStatus
      }]
    }

    const actionButtons = ({ id }) => {
      const keysButton = {
        ...buttons.edit,
        label: 'Keys',
        can: canViewKeys,
        to: buildRoute(path.giftCardKeys, { params: { id } })
      }

      return [keysButton]
    }

    const columns = [
      {
        key: 'id',
        sortable: true
      },
      {
        key: 'amount',
        label: 'Gift card',
        sortable: true,
        defaultSort: sortOrders.asc,
        component: VxCellMoney
      },
      {
        key: 'kinguin_price',
        component: VxCellMoney
      },
      {
        key: 'kinguin_updated_at',
        label: 'Last gate amount sync',
        component: VxCellDate
      },
      {
        key: 'total_keys',
        label: 'Amount'
      },
      {
        key: 'total_sold_keys',
        label: 'Sold'
      },
      {
        key: 'total_buyable_keys',
        label: 'Buyable'
      },
      {
        key: 'is_active',
        label: 'Status',
        sortable: true,
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: statusButton(item)
        })
      },
      {
        key: 'actions',
        label: 'Details',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      },
      {
        key: 'updated_at',
        component: VxCellDate
      }
    ]

    return {
      tableRef,
      resource,
      columns,
      canCreate,

      entity,
      handleSubmit,

      modalTitle,
      modalIsOpen,
      openModal,
      closeModal
    }
  }
}
</script>
