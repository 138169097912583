<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.amount"
      :rules="rules.amount"
      name="amount"
      label="Amount ($)"
    />

    <vx-input
      v-model="formValue.kinguin_product_id"
      :rules="rules.kinguin_product_id"
      name="kinguin_product_id"
      label="Kinguin Product Id"
    />

    <vx-input
      v-model="formValue.kinguin_id"
      :rules="rules.kinguin_id"
      name="kinguin_id"
      label="Kinguin Offer Id"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { VxForm, VxInput } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { giftCards, passDataToResource } from '@/services/resources'
import { formatToCents } from '@/services/utils'

export default {
  name: 'GiftCardEditor',
  components: {
    VxForm,
    VxInput,
    VxButton
  },
  props: {
    giftCard: {
      type: Object,
      default: null
    }
  },
  setup ({ giftCard }, { emit }) {
    const formValue = reactive({
      amount: 1,
      kinguin_id: null,
      kinguin_product_id: null
    })

    const frontToBackMapper = ({ amount, ...rest }) => {
      return {
        amount: formatToCents(amount),
        ...rest
      }
    }

    const formResource = passDataToResource(giftCards.create, {
      frontToBackMapper
    })

    const rules = {
      amount: {
        required: true,
        decimal: 2,
        min_value: 1,
        max_value: 1000
      },
      kinguin_id: {
        required: true
      },
      kinguin_product_id: {
        required: true
      }
    }

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res)
      }
    }

    return {
      formResource,
      formValue,
      rules,
      handleSubmit
    }
  }
}
</script>
